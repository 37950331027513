import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

// Create pallete on https://material.io/resources/color/

declare module "@mui/material/styles" {
  interface Palette {
    black: Palette["primary"];
    milky: Palette["primary"];
  }
  interface PaletteOptions {
    black: PaletteOptions["primary"];
    milky: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    black: true;
    milky: true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    black: true;
    milky: true;
  }
}

let theme = createTheme();

theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#c0a21f",
      light: "#db0411",
      dark: "#b89900",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#757575",
      light: "#9e9e9e",
      dark: "#5b5b5b",
      contrastText: "#fff",
    },
    black: {
      main: "#212121",
      light: "#484848",
      dark: "#000000",
      contrastText: "#fff",
    },
    milky: {
      main: "#ebebeb",
      light: "#ffffff",
      dark: "#c0c0c0",
      contrastText: "#000",
    },
    background: {
      default: "#8e0202",
      paper: "#8e0202",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif", "Arial"].join(","),
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "inherit",
          fontSize: "1em",
          borderRadius: 100,
          paddingLeft: "2em",
          paddingRight: "2em",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 100,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: "#FFF",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "100px !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "rgba(255,255,255,.3)",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "white",
          opacity: "0.35",
          borderColor: "#FFF",
          "&:before": {
            borderColor: "#FFF",
          },
          "&:after": {
            borderColor: "#FFF",
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const ThemesProvider = (props: any) => {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemesProvider;
