import { createContext, useContext } from "react";

interface State {
  state?: any;
  // eslint-disable-next-line no-unused-vars
  open: (message: any, severity?: any) => void;
  close: () => void;
  // eslint-disable-next-line no-unused-vars
  hideCloseButton: (buttonState: boolean) => void;
}

export const SnackbarContext = createContext<State | null>(null);

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
