import { createContext, useContext } from "react";

interface State {
  user?: any;
  logout: () => void;
  login: (data: any) => void;
  tokenVerify: () => boolean;
}

export const AuthContext = createContext<State | null>(null);

export const useAuth = () => {
  return useContext(AuthContext);
};
