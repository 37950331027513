import AppRoutes from "./routes";
import ThemesProvider from "./providers/ThemeProvider";
import AuthProvider from "./providers/AuthProvider";
import SnackbarProvider from "./providers/SnackbarProvider";
import ModalProvider from "./providers/ModalProvider";

export default function App() {
  return (
    <ThemesProvider>
      <AuthProvider>
        <SnackbarProvider>
          <ModalProvider>
            <AppRoutes />
          </ModalProvider>
        </SnackbarProvider>
      </AuthProvider>
    </ThemesProvider>
  );
}
