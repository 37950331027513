import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

const MainLayout = React.lazy(() => import("../layouts/Main/Main"));

const Shipping = React.lazy(() => import("../views/private/Shipping"));

const PaymentOptions = React.lazy(
  () => import("../views/private/PaymentOptions"),
);
const PaymentOptionCreditCard = React.lazy(
  () => import("../views/private/PaymentOptions/CreditCard"),
);
const PaymentOptionPix = React.lazy(
  () => import("../views/private/PaymentOptions/Pix"),
);
const PaymentOptionBankSlip = React.lazy(
  () => import("../views/private/PaymentOptions/BankSlip"),
);

const Confirmation = React.lazy(() => import("../views/private/Confirmation"));
const Done = React.lazy(() => import("../views/private/Done"));
const MyAccount = React.lazy(() => import("../views/private/MyAccount"));
const MyOrders = React.lazy(() => import("../views/private/MyOrders"));

export default function PrivateRoutes() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          index
          path="shipping"
          element={
            <React.Suspense fallback={<>...</>}>
              <Shipping />
            </React.Suspense>
          }
        />
        <Route
          index
          path="confirmation"
          element={
            <React.Suspense fallback={<>...</>}>
              <Confirmation />
            </React.Suspense>
          }
        />
        <Route
          index
          path="done"
          element={
            <React.Suspense fallback={<>...</>}>
              <Done />
            </React.Suspense>
          }
        />
        <Route
          index
          path="my-account"
          element={
            <React.Suspense fallback={<>...</>}>
              <MyAccount />
            </React.Suspense>
          }
        />
        <Route
          index
          path="my-orders"
          element={
            <React.Suspense fallback={<>...</>}>
              <MyOrders />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/payment/" element={<MainLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<>...</>}>
              <PaymentOptions />
            </React.Suspense>
          }
        />
        <Route
          index
          path="credit-card"
          element={
            <React.Suspense fallback={<>...</>}>
              <PaymentOptionCreditCard />
            </React.Suspense>
          }
        />
        <Route
          index
          path="pix"
          element={
            <React.Suspense fallback={<>...</>}>
              <PaymentOptionPix />
            </React.Suspense>
          }
        />
        <Route
          index
          path="bankslip"
          element={
            <React.Suspense fallback={<>...</>}>
              <PaymentOptionBankSlip />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
