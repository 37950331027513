import { useCallback, useMemo, useState } from "react";
import { SnackbarContext } from "../contexts/SnackbarContext";
import alerts from "../locales/alerts.json";

export default function SnackbarProvider({ children }: any) {
  const [state, setState] = useState({
    open: false,
    closeButton: true,
    message: "",
    severity: "warning",
  });

  // call this function when you want to open the snackbar
  const parseError = async (response: Response | string) => {
    const { code }: { code: keyof typeof alerts } =
      typeof response === "string" ? { code: response } : await response.json();
    return alerts[code] ?? alerts.unknown;
  };

  const open = useCallback(
    async (response: Response | string) => {
      const { message, severity } = await parseError(response);

      setState({
        ...state,
        open: true,
        message,
        severity,
      });
    },
    [state],
  );

  // call this function when you want to close the snackbar
  const close = useCallback(() => {
    setState({ ...state, open: false, message: "" });
  }, [state]);

  const hideCloseButton = useCallback(
    async (buttonState: boolean) => {
      setState({ ...state, closeButton: buttonState });
    },
    [state],
  );

  const value = useMemo(
    () => ({
      state,
      open,
      close,
      hideCloseButton,
    }),
    [close, hideCloseButton, open, state],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
}
