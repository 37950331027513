import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

const MainLayout = React.lazy(() => import("../layouts/Main/Main"));

const Auth = React.lazy(() => import("../views/public/Auth"));
const Register = React.lazy(() => import("../views/public/Register"));
const Forgot = React.lazy(() => import("../views/public/Forgot"));
const Catalog = React.lazy(() => import("../views/public/Catalog"));
const Order = React.lazy(() => import("../views/public/Order"));

export default function PublicRoutes() {
  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <React.Suspense fallback={<>...</>}>
            <Auth />
          </React.Suspense>
        }
      />
      <Route
        index
        path="/auth"
        element={
          <React.Suspense fallback={<>...</>}>
            <Auth e2eAuth />
          </React.Suspense>
        }
      />
      <Route
        path="forgot"
        element={
          <React.Suspense fallback={<>...</>}>
            <Forgot />
          </React.Suspense>
        }
      />
      <Route element={<MainLayout />}>
        <Route
          path="showcase"
          element={
            <React.Suspense fallback={<>...</>}>
              <Catalog />
            </React.Suspense>
          }
        />
        <Route
          index
          path="order"
          element={
            <React.Suspense fallback={<>...</>}>
              <Order />
            </React.Suspense>
          }
        />
        <Route
          path="register"
          element={
            <React.Suspense fallback={<>...</>}>
              <Register />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
