import React, { createContext, useContext } from "react";

interface State {
  state?: any;
  open: (title: string, children: React.ReactNode) => void;
  close: () => void;
  hideCloseButton: (state: boolean) => void;
}

export const ModalContext = createContext<State | null>(null);

export const useModal = () => {
  return useContext(ModalContext);
};
