import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext/AuthContext";
import PublicRoutes from "./public.routes";
import PrivateRoutes from "./private.routes";

export default function AppRoutes() {
  const auth = useAuth();

  useEffect(() => {
    if (auth?.tokenVerify()) {
      auth?.login({
        userId: localStorage.getItem("userId"),
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          index
          path="/*"
          element={
            <React.Suspense fallback={<>...</>}>
              <PublicRoutes />
              {auth?.user && <PrivateRoutes />}
            </React.Suspense>
          }
        />
      </Routes>
    </Router>
  );
}
