import { useMemo, useState } from "react";
import { ModalContext } from "../contexts/ModalContext";

export default function ModalProvider({ children }: any) {
  const [state, setState] = useState({
    open: false,
    closeButton: true,
    children: null,
    title: "",
  });

  // call this function when you want to open the snackbar
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const open = async (title: string, children: any) => {
    setState({
      ...state,
      open: true,
      children,
      title,
    });
  };

  // call this function when you want to close the snackbar
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const close = () => {
    setState({ ...state, open: false, children: null, title: "" });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hideCloseButton = async (buttonState: boolean) => {
    setState({ ...state, closeButton: buttonState });
  };

  const value = useMemo(
    () => ({
      state,
      open,
      close,
      hideCloseButton,
    }),
    [close, hideCloseButton, open, state],
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
